import { Container, Drawer, makeStyles, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import type { FC } from 'react';
import { useState } from 'react';
import { useRecordContext } from 'react-admin';
import { getAssets } from 'src/calc/transactions/getAssets';
import { AssetForm } from 'src/clients/assets/AssetForm';
import { makeAssetsColumns } from 'src/clients/assets/functions/makeAssetsColumns';
import { makeNewAsset } from 'src/clients/assets/functions/makeNewAsset';
import { useUpdateArrayElements } from 'src/clients/hooks/useUpdateArrayElements';
import { currencyFormatter } from 'src/clients/tables/functions/makeGridColumns';
import type { Client } from 'src/clients/types';
import { AddButton } from 'src/components/buttons/AddButton';
import { DeleteButton } from 'src/components/buttons/DeleteButton';

export const AssetListLive: FC = () => {
  //[Data Grid - Rendering - Material-UI](https://material-ui.com/components/data-grid/rendering/#column-definitions)

  const classes = useInputDataGridStyles();

  const record: Client = useRecordContext();
  if (!record) return null;

  const { assets: entries = [] } = record;
  const { total } = getAssets(record);

  const [currentEntry, setCurrentEntry] = useState<typeof entries[number]>();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const editRow = (rowId: string) => {
    setCurrentEntry(entries.find(({ id }) => id === rowId));
    setDrawerOpen(true);
  };

  const [selected, setSelected] = useState<typeof entries[number]['id'][]>([]);

  const { deleteAssets, response } = useUpdateArrayElements(record);
  const deleteSelectedRows = () => {
    deleteAssets(selected);
  };

  const addRow = () => {
    setCurrentEntry(makeNewAsset());
    setDrawerOpen(true);
  };

  //[React-admin - The List View](https://marmelab.com/react-admin/List.html#rendering-datagrid-with-a-custom-query)
  return (
    <>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        className={classes.drawer}
      >
        {currentEntry && (
          <Container>
            <AssetForm asset={currentEntry} />
          </Container>
        )}
      </Drawer>
      <div>
        <DataGrid
          columns={makeAssetsColumns()}
          rows={entries}
          rowHeight={40}
          autoHeight
          density="compact"
          checkboxSelection
          disableSelectionOnClick
          onRowClick={({ id }) => editRow(id as string)}
          onSelectionModelChange={({ selectionModel }) =>
            setSelected(selectionModel as string[])
          }
          className={classes.datagrid}
          sortModel={[{ field: 'title', sort: 'asc' }]}
        />
        <Typography variant="button" className={classes.total}>
          Total: {currencyFormatter(total)}
        </Typography>
      </div>
      <AddButton onClick={addRow} />
      {!!selected.length && <DeleteButton onClick={deleteSelectedRows} />}
    </>
  );
};

export const useInputDataGridStyles = makeStyles((theme) => ({
  datagrid: {
    '& .MuiDataGrid-row': {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  drawer: {
    '& .MuiDrawer-paper': {
      maxWidth: 'min(400px, 100vw)',
      minWidth: 'min(400px, 100vw)',
      paddingTop: theme.spacing(3),
    },
  },
  total: {
    marginTop: '1rem',
  },
}));
