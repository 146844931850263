import { Container, Drawer } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import type { FC } from 'react';
import { useState } from 'react';
import { useRecordContext } from 'react-admin';
import { v4 } from 'uuid';
import { useInputDataGridStyles } from 'src/clients/assets/AssetListLive';
import { useUpdateArrayElements } from 'src/clients/hooks/useUpdateArrayElements';
import { useLoadedSnapshot } from 'src/clients/snapshots/hooks';
import { makeSnapshotsColumns } from 'src/clients/snapshots/makeSnapshotsColumns';
import { useDispatchSnapshot } from 'src/clients/snapshots/redux';
import {
  snapshotDateFormatter,
  SnapshotForm,
} from 'src/clients/snapshots/SnapshotForm';
import type { Client } from 'src/clients/types';
import { AddButton } from 'src/components/buttons/AddButton';
import { DeleteButton } from 'src/components/buttons/DeleteButton';
import { LoadButton } from 'src/components/buttons/LoadButton';
import type { Snapshot } from 'src/snapshots/types';
export type SnapshotListProps = { retirement?: boolean };

export const SnapshotList: FC<SnapshotListProps> = ({ retirement = false }) => {
  //[Data Grid - Rendering - Material-UI](https://material-ui.com/components/data-grid/rendering/#column-definitions)

  const classes = useInputDataGridStyles();

  const record: Client = useRecordContext();
  if (!record) return null;

  const { snapshots = [], ...recordWithoutSnapshots } = record;

  const [snapshot, setSnapshot] = useState<Snapshot>();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const addRow = () => {
    const timestamp = new Date().toString();
    const title = snapshotDateFormatter(timestamp);
    const newSnap: Snapshot = {
      data: recordWithoutSnapshots,
      date: new Date(),
      id: v4(),
      title,
    };
    setSnapshot(newSnap);
    setDrawerOpen(true);
  };
  const editRow = (rowId: string) => {
    setSnapshot(snapshots.find(({ id }) => id === rowId));
    setDrawerOpen(true);
  };

  const [selected, setSelected] = useState<Snapshot['id'][]>([]);
  const { deleteSnapshots } = useUpdateArrayElements(record);
  const deleteSelectedRows = () => {
    deleteSnapshots(selected);
    setSelected([]);
  };

  // LOAD SNAPSHOT
  const dispatchSnapshot = useDispatchSnapshot();
  const loadSnapshot = () => {
    dispatchSnapshot(selected[0]);
    // handleClose();
  };

  const loadLiveData = () => dispatchSnapshot();

  const loadedSnapshotId = useLoadedSnapshot()?.id;
  const isLiveData = !loadedSnapshotId;

  const rows = snapshots.map((snapshot) => ({
    ...snapshot,
    loaded: snapshot?.id === loadedSnapshotId,
  }));

  //[React-admin - The List View](https://marmelab.com/react-admin/List.html#rendering-datagrid-with-a-custom-query)
  return (
    <>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        className={classes.drawer}
      >
        {snapshot && (
          <Container>
            <SnapshotForm {...{ snapshot, retirement }} />
          </Container>
        )}
      </Drawer>
      <div>
        <DataGrid
          columns={makeSnapshotsColumns()}
          rows={rows}
          rowHeight={40}
          autoHeight
          density="compact"
          checkboxSelection
          disableSelectionOnClick
          onRowClick={({ id }) => editRow(id as string)}
          onSelectionModelChange={({ selectionModel }) =>
            setSelected(selectionModel as string[])
          }
          className={classes.datagrid}
          sortModel={[
            { field: 'date', sort: 'desc' },
            { field: 'title', sort: 'asc' },
          ]}
        />
      </div>
      {!selected.length && <AddButton onClick={addRow} />}
      {selected.length === 1 && selected[0] !== loadedSnapshotId && (
        <LoadButton onClick={loadSnapshot} />
      )}
      {!isLiveData && (
        <LoadButton onClick={loadLiveData}>Back to Live Data</LoadButton>
      )}
      {!!selected.length && <DeleteButton onClick={deleteSelectedRows} />}
    </>
  );
};
